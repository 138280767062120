
import Vue from "vue";

import { NoticeResponse, Notice, Breadcrumb } from "@/interfaces";

export default Vue.extend({
  computed: {
    breadcrumbs(): Breadcrumb[] {
      const { target } = this.$route.params;

      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/"
        },
        {
          text: `お知らせ`,
          disabled: true,
          to: "/notice"
        }
      ];
    },
    headers() {
      return [
        {
          text: "作成日",
          value: "created_at"
        },
        {
          text: "作成者",
          value: "user"
        },
        {
          text: "タイトル",
          value: "title"
        },
        {
          text: "コンテンツ",
          value: "content"
        },
        {
          text: "ステータス",
          value: "status"
        },
        {
          text: "添付",
          value: "attachments"
        },
        { text: "", value: "action", sortable: false }
      ];
    }
  },
  async mounted() {
    const { target } = this.$route.params;

    if (!["owner", "member"].includes(target)) {
      await this.$router.replace("/notices/owner");
    }
  }
});
